import { Fragment, useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { faCircle, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Image from 'components/display/Image';
import iconPlaceholder from 'assets/images/icon-placeholder.svg';
import routes from 'constants/routes';
import AnchorLink from 'components/interactive/AnchorLink';
import LinkButton from 'components/interactive/LinkButton';
import Icon from 'components/display/Icon';
import Button from 'components/interactive/Button';
import Hexagon from 'components/display/Hexagon';
import { Theme } from 'constants/page';
import Portal from 'components/overlay/Portal';
import { useNoBodyScroll } from 'utils/dom';
import VetcoveLogo from 'components/display/VetcoveLogo';

import styles from './Header.module.scss';

type NavItem = {
  label: string;
  href: string;
  className?: string;
};

type Dropdown = {
  // Values are set from visual alignment on the page
  position: { left: number; width: number };
  render: () => ReactNode;
};

type DropdownItem = {
  label: string;
  dropdown: Dropdown;
  className?: string;
};

type HeaderItem = NavItem | DropdownItem;

const isNavItem = (item: HeaderItem): item is NavItem => 'href' in item;

const items: HeaderItem[] = [
  {
    label: 'Platform',
    dropdown: {
      position: { left: 220, width: 460 },
      render: () => {
        const sections = [
          {
            label: 'Procurement Marketplace',
            items: [
              {
                tag: '100% Free',
                label: 'Marketplace Clinics',
                description: 'Free to veterinary clinics & nonprofits',
                image: iconPlaceholder,
                href: routes.marketplace.clinics,
              },
            ],
          },
        ];

        const subitems = [
          {
            label: 'Brands',
            href: routes.marketplace.brands,
          },
          {
            label: 'Merchants',
            href: routes.marketplace.merchants,
          },
          {
            label: 'Hospital Groups',
            href: routes.marketplace.enterprise,
          },
          {
            label: 'FAQ',
            href: routes.faq,
          },
        ];

        return (
          <div className={styles.platform}>
            <div className={styles.sections}>
              {sections.map((section) => (
                <div className={styles.section} key={section.label}>
                  <div className={styles.sectionItems}>
                    {section.items.map((item) => (
                      <AnchorLink href={item.href} className={styles.sectionItem} key={item.label}>
                        <Hexagon width={40}>
                          <Image src={item.image} />
                        </Hexagon>
                        <div className={styles.sectionItemContent}>
                          <div className={styles.sectionItemLabel}>
                            <span>{item.label}</span>
                            {item.tag && <span className={styles.sectionItemTag}>{item.tag}</span>}
                          </div>
                          <div className={styles.sectionItemDescription}>{item.description}</div>
                        </div>
                      </AnchorLink>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.subitems}>
              {subitems.map((item, index) => (
                <Fragment key={item.label}>
                  {!!index && <Icon className={styles.dot} icon={faCircle} />}
                  <AnchorLink href={item.href} className={styles.subitem}>
                    {item.label}
                  </AnchorLink>
                </Fragment>
              ))}
            </div>
          </div>
        );
      },
    },
  },
  {
    label: 'Company',
    dropdown: {
      position: { left: 350, width: 300 },
      render: () => {
        const subitems = [
          {
            label: 'About Vetcove',
            description: 'Learn more about the origins',
            image: iconPlaceholder,
            href: routes.about,
          },
          {
            label: 'Careers',
            description: 'See open positions',
            image: iconPlaceholder,
            href: routes.careers,
          },
          {
            label: 'Cove Crew',
            description: 'Meet the ambassadors',
            image: iconPlaceholder,
            href: routes.ambassadors,
          },
        ];

        return (
          <div className={styles.companyItems}>
            {subitems.map((item) => (
              <AnchorLink href={item.href} key={item.label} className={styles.companyItem}>
                <div className={styles.companyItemIcon}>
                  <Image src={item.image} />
                </div>
                <div className={styles.companyItemContent}>
                  <div className={styles.companyItemLabel}>{item.label}</div>
                  <div className={styles.companyItemDescription}>{item.description}</div>
                </div>
              </AnchorLink>
            ))}
          </div>
        );
      },
    },
  },
  {
    label: 'Learn',
    dropdown: {
      position: { left: 480, width: 300 },
      render: () => {
        const subitems = [
          {
            label: 'FAQ',
            description: 'Answers to your questions',
            image: iconPlaceholder,
            href: routes.faq,
          },
          {
            label: 'Help Center',
            description: 'Read help articles',
            image: iconPlaceholder,
            href: routes.external.help,
            isExternal: true,
          },
          {
            label: "Who's Using",
            description: 'See testimonials from real users',
            image: iconPlaceholder,
            href: routes.customers,
            className: styles.more,
          },
        ];

        return (
          <div className={styles.companyItems}>
            {subitems.map((item) => (
              <LinkButton
                href={item.href}
                key={item.label}
                isExternal={item.isExternal}
                className={`${styles.companyItem} ${item.className ?? ''}`}
              >
                <div className={styles.companyItemIcon}>
                  <Image src={item.image} />
                </div>
                <div className={styles.companyItemContent}>
                  <div className={styles.companyItemLabel}>{item.label}</div>
                  <div className={styles.companyItemDescription}>{item.description}</div>
                </div>
              </LinkButton>
            ))}
          </div>
        );
      },
    },
  },
  {
    label: "Who's Using",
    href: routes.customers,
    className: styles.extra,
  },
];

type FinalPosition = { width: number; height: number; left: number; offset: number };

type DropdownContentProps = {
  dropdown: Dropdown;
  index: number;
  activeIndex: number;
  onActive: (position: FinalPosition) => void;
};

const DropdownContent = (props: DropdownContentProps) => {
  const { dropdown, index, activeIndex, onActive } = props;

  // Helpers to apply the correct transition CSS based on the direction the dropdown is moving to
  const isActive = activeIndex === index;
  const isBefore = index < activeIndex;
  const isAfter = index > activeIndex;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = ref.current;

    if (isActive && div) {
      // If the box is going to overflow the window, calculate how much to offset it to fit
      const padding = 32;
      const offset =
        Math.max(padding + div.offsetWidth + dropdown.position.left - window.innerWidth, 0) * -1;

      onActive({
        width: div.offsetWidth,
        height: div.offsetHeight,
        left: dropdown.position.left,
        offset,
      });
    }
  }, [isActive, onActive, dropdown.position.left]);

  return (
    <div
      ref={ref}
      className={`${styles.dropdownContent} ${isActive ? styles.active : ''} ${
        isBefore ? styles.before : ''
      } ${isAfter ? styles.after : ''}`}
      style={dropdown.position}
    >
      {dropdown.render()}
    </div>
  );
};

type Item = {
  label: string;
  href: string;
  image: string;
  description?: string;
};

type MobileDropdownProps = {
  isVisible: boolean;
  onClose: () => void;
};

const MobileDropdown = (props: MobileDropdownProps) => {
  const { isVisible, onClose } = props;

  useNoBodyScroll(isVisible);

  const renderLargeItems = (largeItems: Item[]) => {
    return (
      <div className={styles.largeItems}>
        {largeItems.map((item) => (
          <AnchorLink href={item.href} className={styles.link} key={item.label}>
            <Hexagon width={32}>
              <Image src={item.image} />
            </Hexagon>
            <div className={styles.detail}>
              <div className={styles.label}>{item.label}</div>
              <div className={styles.description}>{item.description}</div>
            </div>
          </AnchorLink>
        ))}
      </div>
    );
  };

  const renderSmallItems = (smallItems: Item[]) => {
    return (
      <div className={styles.smallItems}>
        {smallItems.map((item) => (
          <AnchorLink href={item.href} className={styles.link} key={item.label}>
            <Image width={16} src={item.image} />
            <div className={styles.detail}>
              <div className={styles.label}>{item.label}</div>
            </div>
          </AnchorLink>
        ))}
      </div>
    );
  };

  const sections = [
    {
      label: 'Procurement Marketplace',
      render: renderLargeItems,
      items: [
        {
          label: 'Marketplace for Clinics',
          description: 'Free to veterinary clinics & nonprofits',
          image: iconPlaceholder,
          href: routes.marketplace.clinics,
        },
        {
          label: 'Marketplace for Hospital Groups',
          description: 'Free to veterinary clinics & nonprofits',
          image: iconPlaceholder,
          href: routes.marketplace.enterprise,
        },
      ],
    },
    {
      label: 'Company',
      render: renderSmallItems,
      items: [
        {
          label: 'About Vetcove',
          image: iconPlaceholder,
          href: routes.about,
        },
        {
          label: 'Careers',
          image: iconPlaceholder,
          href: routes.careers,
        },
        {
          label: "Who's Using",
          image: iconPlaceholder,
          href: routes.customers,
        },
        {
          label: 'Cove Crew',
          image: iconPlaceholder,
          href: routes.ambassadors,
        },
        {
          label: 'Privacy Policy',
          image: iconPlaceholder,
          href: routes.privacy,
        },
        {
          label: 'Assets',
          image: iconPlaceholder,
          href: routes.assets,
        },
      ],
    },
    {
      label: 'More',
      render: renderSmallItems,
      items: [
        {
          label: 'For Brands',
          image: iconPlaceholder,
          href: routes.marketplace.brands,
        },
        {
          label: 'For Merchants',
          image: iconPlaceholder,
          href: routes.marketplace.merchants,
        },
        {
          label: 'Learn',
          image: iconPlaceholder,
          href: routes.external.learn,
        },
        {
          label: "FAQ's",
          image: iconPlaceholder,
          href: routes.faq,
        },
      ],
    },
  ];

  return (
    <Portal>
      <div className={`${styles.mobileDropdown} ${isVisible ? '' : styles.dropdownHidden}`}>
        <div className={styles.mobileHeader}>
          <AnchorLink href={routes.home}>
            <VetcoveLogo />
          </AnchorLink>
          <button className={styles.close} type='button' onClick={onClose}>
            <Icon icon={faTimes} />
          </button>
        </div>
        <div className={styles.links}>
          {sections.map((section) => (
            <div className={styles.section} key={section.label}>
              <div className={styles.heading}>{section.label}</div>
              <div className={styles.main}>{section.render(section.items)}</div>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button
              variant='primary'
              hasArrow={true}
              className={styles.button}
              href={routes.external.signUp}
            >
              Get Started
            </Button>
            <Button
              variant='secondary'
              hasArrow={true}
              className={styles.button}
              href={routes.external.signIn}
            >
              Login to Site
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

type HeaderProps = { theme: Theme };

const Header = ({ theme }: HeaderProps) => {
  const [isMobileDropdownVisible, setIsMobileDropdownVisible] = useState(false);
  const [dropdown, setDropdown] = useState<{
    // If the dropdown is visible to the user
    isVisible: boolean;
    // If the drodown is being opened for the first time
    isFresh: boolean;
    // The dropdown item that's opened
    item: DropdownItem | null;
  }>({
    isVisible: false,
    isFresh: true,
    item: null,
  });
  const [box, setBox] = useState<FinalPosition | null>(null);

  /** Reset the header position once closed to avoid movement animation from previous location */
  useEffect(() => {
    if (!dropdown.isVisible) {
      setTimeout(() => {
        setDropdown((d) => ({ ...d, isFresh: true, item: null }));
      }, parseInt(styles.transitionDuration, 10));
    }
  }, [dropdown.isVisible]);

  const dropdownItems = items.flatMap((item) => ('dropdown' in item ? [item] : []));
  const activeIndex = dropdownItems.findIndex((item) => item.label === dropdown.item?.label);
  const isDarkTheme = theme === 'dark';

  return (
    <header
      className={`${styles.header} ${isDarkTheme ? styles.dark : ''}`}
      onMouseLeave={() => setDropdown((d) => ({ ...d, isVisible: false }))}
    >
      <div className={styles.content}>
        <AnchorLink href={routes.home}>
          <VetcoveLogo variant={isDarkTheme ? 'white' : 'default'} />
        </AnchorLink>
        <div className={styles.items}>
          {items.map((item) =>
            isNavItem(item) ? (
              <AnchorLink
                key={item.label}
                href={item.href}
                className={`${styles.item} ${item.className ?? ''}`}
                onMouseEnter={() => setDropdown((d) => ({ ...d, isVisible: false }))}
              >
                {item.label}
              </AnchorLink>
            ) : (
              <button
                key={item.label}
                type='button'
                className={`${styles.item} ${item.className ?? ''} ${
                  dropdown.isVisible && item.label === dropdown.item?.label ? styles.active : ''
                }`}
                onMouseEnter={() =>
                  setDropdown((d) => ({ ...d, isVisible: true, isFresh: !d.isVisible, item }))
                }
              >
                {item.label}
                <Icon className={styles.angle} icon={faAngleUp} />
              </button>
            )
          )}
        </div>
        <div className={styles.actions}>
          <AnchorLink className={styles.item} href={routes.external.signIn}>
            Sign In
          </AnchorLink>
          <Button
            variant={isDarkTheme ? 'secondary' : 'primary'}
            className={styles.button}
            href={routes.external.signUp}
          >
            Get Started
          </Button>
        </div>
        <div
          className={`${styles.dropdown} ${dropdown.isVisible ? '' : styles.hidden} ${
            dropdown.isFresh ? styles.fresh : ''
          }`}
          style={{
            width: box?.width,
            height: box?.height,
            left: box?.left,
            transform: `translateX(${box?.offset}px)`, // Prevent overflowing the window
          }}
        >
          <div
            className={styles.position}
            style={{
              // Offset the position so it fits in the box
              left: -(box?.left ?? 0),
            }}
          >
            {dropdownItems.map((item, index) => (
              <DropdownContent
                key={item.label}
                dropdown={item.dropdown}
                index={index}
                activeIndex={activeIndex}
                onActive={setBox}
              />
            ))}
          </div>
        </div>
        <div className={styles.mobileMenu}>
          <button
            type='button'
            className={styles.item}
            onClick={() => setIsMobileDropdownVisible(true)}
          >
            <Icon icon={faBars} />
          </button>
          <MobileDropdown
            isVisible={isMobileDropdownVisible}
            onClose={() => setIsMobileDropdownVisible(false)}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
