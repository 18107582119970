import type { ReactNode } from 'react';
import { faFacebookSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { useIntercom } from 'react-use-intercom';

import hexagons from 'assets/images/footer/hexagons.svg';
import Icon from 'components/display/Icon';
import { Theme } from 'constants/page';
import routes from 'constants/routes';
import AnchorLink from 'components/interactive/AnchorLink';
import LinkButton from 'components/interactive/LinkButton';
import VetcoveLogo from 'components/display/VetcoveLogo';

import styles from './Footer.module.scss';

const Navigation = () => {
  const { showNewMessages: openNewIntercomMessage } = useIntercom();

  const categories = [
    {
      title: 'Marketplace',
      links: [
        { label: 'Veterinary Clinics', link: routes.marketplace.clinics },
        { label: 'Animal Health Brands', link: routes.marketplace.brands },
        { label: 'Hospital Groups', link: routes.marketplace.enterprise },
        { label: 'Merchants', link: routes.marketplace.merchants },
        { label: 'FAQs', link: routes.faq },
      ],
    },
    {
      title: 'Company',
      links: [
        { label: 'Cove Crew', link: routes.ambassadors },
        { label: 'Customers', link: routes.customers },
        { label: 'Careers', link: routes.careers },
        { label: 'About', link: routes.about },
      ],
    },
    {
      title: 'Resources',
      links: [
        { label: 'Help Center', link: routes.external.help, isExternal: true },
        { label: 'FAQs', link: routes.faq },
        { label: 'Contact', onClick: () => openNewIntercomMessage() },
      ],
    },
  ];

  return (
    <div className={styles.categories}>
      {categories.map(({ title, links }) => (
        <div key={title} className={styles.category}>
          <div className={styles.title}>{title}</div>
          <div className={styles.links}>
            {links.map(({ label, link, onClick, isExternal }) => (
              <LinkButton
                href={link}
                key={label}
                className={styles.link}
                onClick={onClick}
                isExternal={isExternal}
              >
                {label}
              </LinkButton>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const Details = () => {
  const links = [
    { label: 'Privacy Policy', link: routes.privacy },
    { label: 'Terms of Use', link: routes.termsOfUse },
    { label: 'Press & Media', link: routes.assets },
    { label: 'Seller Agreement', link: routes.sellerAgreement },
  ];
  return (
    <div className={styles.details}>
      <div>
        © Copyright {new Date().toLocaleString('en-US', { year: 'numeric' })} Vetcove Inc. All
        rights reserved.
      </div>
      <div className={styles.links}>
        {links.map(({ label, link }) => (
          <AnchorLink href={link} key={label} className={styles.link}>
            {label}
          </AnchorLink>
        ))}
      </div>
    </div>
  );
};

type CompanyProps = { theme: Theme };

const Company = ({ theme }: CompanyProps) => {
  const socials = [
    { link: routes.external.facebook, icon: faFacebookSquare },
    { link: routes.external.twitter, icon: faTwitterSquare },
    { link: routes.external.linkedin, icon: faLinkedin },
  ];

  return (
    <div className={styles.company}>
      <div>
        <AnchorLink href={routes.home}>
          <VetcoveLogo width={108} height={27} variant={theme === 'dark' ? 'white' : 'grey'} />
        </AnchorLink>
        <div className={styles.slogan}>Veterinary Starts Here.</div>
      </div>
      <div className={styles.socials}>
        {socials.map(({ link, icon }) => (
          <AnchorLink href={link} key={link} className={styles.social}>
            <Icon icon={icon} />
          </AnchorLink>
        ))}
      </div>
    </div>
  );
};

type FooterProps = { theme: Theme; cta?: ReactNode };

const Footer = ({ theme, cta = null }: FooterProps) => {
  return (
    <footer className={`${styles.footer} ${theme === 'dark' ? styles.dark : ''}`}>
      {theme === 'light' && (
        <div className={styles.hexagons} style={{ backgroundImage: `url(${hexagons.src})` }} />
      )}
      <div className={styles.content}>
        {cta && <div className={styles.cta}>{cta}</div>}
        <div className={styles.main}>
          <Company theme={theme} />
          <Navigation />
        </div>
        <div className={styles.divider} />
        <Details />
      </div>
    </footer>
  );
};

export default Footer;
