/** Routes that map up to pages in /pages for consistent navigation */
const routes = {
  about: '/about',
  assets: '/assets',
  careers: '/careers',
  ambassadors: '/ambassadors',
  faq: '/faq',
  home: '/',
  marketplace: {
    clinics: '/marketplace/clinics',
    enterprise: '/marketplace/enterprise',
    brands: '/marketplace/brands',
    merchants: '/marketplace/merchants',
  },
  pharmacy: {
    clinics: '/pharmacy/clinics',
    enterprise: '/pharmacy/enterprise',
  },
  privacy: '/privacy',
  termsOfUse: '/terms-of-use',
  customers: '/customers',
  sellerAgreement: '/seller-agreement',
  // Routes outside of the nextjs app
  external: {
    signIn: '/signin',
    signUp: '/accounts/signup',
    avma: '/avma',
    schedule: 'https://calendly.com/vetcove/tutorial',
    help: 'https://help.vetcove.com/',
    learn: 'https://learn.vetcove.com/',
    facebook: 'https://www.facebook.com/vetcove/',
    twitter: 'https://www.twitter.com/vetcove/',
    linkedin: 'https://www.linkedin.com/company/vetcove/',
  },
};

export default routes;
