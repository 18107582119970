import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: ReactNode;
};

/**
 * NextJS allows for server sider rendering, so anything with React DOM must be done on the client
 * only, which can be determined if a useEffect is called
 * @see https://github.com/vercel/next.js/blob/canary/examples/with-portals/components/ClientOnlyPortal.js
 */
const Portal = (props: PortalProps) => {
  const { children } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? createPortal(children, document.body) : null;
};

export default Portal;
