import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { ReactNode } from 'react';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'components/display/Icon';

import AnchorLink from './AnchorLink';

import styles from './LinkButton.module.scss';

export type LinkButtonProps = {
  children: ReactNode;
  isExternal?: boolean;
  arrowIcon?: IconDefinition;
  hasArrow?: boolean;
  className?: string;
  href?: string;
  onClick?: () => void;
};

const LinkButton = (props: LinkButtonProps) => {
  const {
    href,
    isExternal,
    hasArrow,
    arrowIcon = faArrowRight,
    className = '',
    children,
    ...other
  } = props;

  const newProps = {
    className: `${styles.container} ${className}`,
    children: (
      <>
        {children}
        {hasArrow && <Icon className={styles.arrow} icon={arrowIcon} />}
      </>
    ),
    ...other,
  };

  if (href) {
    const externalProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {};
    return <AnchorLink href={href} {...externalProps} {...newProps} />;
  }

  return <button type='button' {...newProps} />;
};

export default LinkButton;
