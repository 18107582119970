import React from 'react';
import NextHead from 'next/head';

type HeadProps = {
  title: string;
};

const Head = (props: HeadProps) => {
  const { title } = props;

  return (
    <NextHead>
      {/* Page information */}
      <title>Vetcove | {title}</title>
      <meta
        name='description'
        content='Vetcove is the single source for veterinarians to research and buy supplies, pharmaceuticals, equipment, diagnostics, biologics for dogs, cats, equine &#38; more!'
      />
      <meta
        name='keywords'
        content='Veterinary, Animal, Supplies, Pharmaceuticals, Equipment, Diagnostics, Biologics, Insect, Parasite, Cat, Dog, Horse, Cow, Equine, Small Animal, Large Animal, Health, Food, Hospital, Clinic, Henry Schein, Patterson Vet, MWI, Midwest Vet, AHI'
      />

      {/* Responsiveness */}
      <meta httpEquiv='content-type' content='text/html;charset=UTF-8' />
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />

      {/* Open Graph Information - Used by social networks for the open graph protocol */}
      <meta property='og:url' content='https://www.vetcove.com' />
      <meta property='og:title' content='Shop all your suppliers in one place' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Vetcove' />
      <meta
        property='og:description'
        content='Opening multiple tabs to compare pricing is challenging. Vetcove makes it easy. Always free. Trusted by vet clinics everywhere.'
      />
      <meta property='og:image' content='/images/og-image.jpeg' />

      {/* Identifiers */}
      <meta property='fb:app_id' content='220149978181828' />
      <meta name='google-site-verification' content='OTvvPPnrrnTDsXZgcGzeqGrrU8kPzGaNPvGG73JIXfw' />

      {/* Favicon and Icons */}
      <link rel='icon' href='/favicon.png' />
      <meta name='application-name' content='Vetcove' />
      <meta name='msapplication-square150x150logo' content='/images/windows-icon.png' />
      <meta name='apple-mobile-web-app-title' content='Vetcove' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-touch-fullscreen' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='black' />
      <link rel='apple-touch-icon' sizes='180x180' href='/images/apple-icon.png' />
    </NextHead>
  );
};

export default Head;
