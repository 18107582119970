import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

/**
 * Wrapper for FontAwesomeIcon
 * To keep bundle size small, only import the icons needed!
 * import { faHomeHeart } from '@fortawesome/pro-regular-svg-icons';
 * See https://fontawesome.com/how-to-use/on-the-web/using-with/react
 */
const Icon = (props: FontAwesomeIconProps) => {
  return <FontAwesomeIcon {...props} />;
};

export default Icon;
