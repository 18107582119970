import type { ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';

type AnchorLinkProps = LinkProps & {
  children: ReactNode;
  target?: string;
  rel?: string;
  className?: string;
  onMouseEnter?: () => void;
};

/**
 * Custom internal Link component to better gracefully handle accessibility and nextjs
 * @see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md#case-i-use-nextjs-and-im-getting-this-error-inside-of-links
 */
const AnchorLink = (props: AnchorLinkProps) => {
  const { className, children, onMouseEnter, target, rel, ...other } = props;

  return (
    <Link {...other} passHref={true}>
      <a href='replace' className={className} onMouseEnter={onMouseEnter} target={target} rel={rel}>
        {children}
      </a>
    </Link>
  );
};

export default AnchorLink;
