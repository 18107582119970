import type { ReactNode } from 'react';

import { Theme } from 'constants/page';

import Head from './Head';
import Header from './Header';
import Footer from './Footer';

import styles from './Page.module.scss';

type PageProps = {
  title: string;
  children: ReactNode;
  headerTheme?: Theme;
  footerTheme?: Theme;
  banner?: ReactNode;
  cta?: ReactNode;
};

const Page = (props: PageProps) => {
  const {
    title,
    children,
    headerTheme = 'light',
    footerTheme = 'light',
    banner = null,
    cta = null,
  } = props;

  return (
    <div className={styles.container}>
      <Head title={title} />
      {banner}
      <Header theme={headerTheme} />
      {children}
      <Footer cta={cta} theme={footerTheme} />
    </div>
  );
};

export default Page;
