import { ReactNode, SVGProps } from 'react';

import styles from './Hexagon.module.scss';
import styleVars from '../../styles/export-variables.module.scss';

type HexagonProps = SVGProps<SVGSVGElement> & {
  width: number;
  className?: string;
  angleUp?: boolean;
  children?: ReactNode;
};

const Hexagon = ({
  width,
  className = '',
  angleUp = true,
  children,
  fill,
  stroke,
  strokeWidth,
  ...rest
}: HexagonProps) => (
  <div
    style={{ width: `${width}px`, height: `${width}px` }}
    className={`${styles.container} ${className}`}
  >
    <svg
      width={width}
      height={width}
      className={`${!angleUp ? styles.rotated : ''}`}
      viewBox='0 0 42 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M19 1.1547C20.2376 0.440169 21.7624 0.440169 23 1.1547L39.7846 10.8453C41.0222 11.5598 41.7846 12.8803 41.7846 14.3094V33.6906C41.7846 35.1197 41.0222 36.4402 39.7846 37.1547L23 46.8453C21.7624 47.5598 20.2376 47.5598 19 46.8453L2.21539 37.1547C0.977786 36.4402 0.215391 35.1197 0.215391 33.6906L0.215391 14.3094C0.215391 12.8803 0.977787 11.5598 2.21539 10.8453L19 1.1547Z'
        fill={fill ?? styleVars.primary50}
        stroke={stroke ?? styleVars.primary100}
        strokeWidth={strokeWidth ?? '0.8'}
      />
    </svg>
    <div className={`${styles.content}`}>{children}</div>
  </div>
);

export default Hexagon;
