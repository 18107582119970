import type { SVGProps } from 'react';

import styles from './VetcoveLogo.module.scss';

type LogoProps = {
  variant?: 'default' | 'white' | 'grey';
};

type VetcoveLogoProps = LogoProps & SVGProps<SVGSVGElement>;

const VetcoveLogo = (props: VetcoveLogoProps) => {
  const { className, variant = 'default', ...other } = props;

  return (
    <svg
      width={145}
      height={36}
      viewBox='0 0 3845 862'
      fill='none'
      className={`${styles.container} ${styles[variant]}`}
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M451.156 56.1973C420.616 37.8824 382.641 37.1899 351.454 54.3793L96.3339 194.994C64.423 212.582 44.6045 246.135 44.6045 282.572L44.6045 568.37C44.6045 603.232 62.7601 635.577 92.5198 653.735L340.195 804.852C370.846 823.553 409.17 824.387 440.604 807.036L695.128 666.546C727.009 648.949 746.804 615.412 746.804 578.997V290.133C746.804 254.999 728.366 222.443 698.235 204.373L451.156 56.1973ZM664.328 530.798C663.805 566.691 644.083 599.551 612.654 616.895L443.472 710.255C412.49 727.352 374.779 726.802 344.308 708.809L178.098 610.657C147.189 592.405 128.434 558.986 128.957 523.093L131.719 333.483C132.242 297.589 151.964 264.73 183.393 247.386L352.575 154.025C383.558 136.928 421.269 137.478 451.739 155.472L617.95 253.623C648.859 271.876 667.613 305.295 667.09 341.187L664.328 530.798Z'
        className={styles.text}
      />
      <path
        d='M997.989 636.505C1004.06 657.327 1017.95 666.003 1038.77 666.003H1108.19C1129.01 666.003 1142.03 657.327 1148.97 636.505L1282.59 265.181C1291.27 240.021 1279.99 223.537 1253.09 223.537H1212.31C1191.48 223.537 1177.6 233.08 1171.53 253.902L1089.1 497.692C1080.42 523.719 1073.48 564.496 1073.48 564.496H1071.74C1071.74 564.496 1063.93 523.719 1055.26 497.692L972.826 253.902C966.752 233.08 952.869 223.537 932.044 223.537H891.263C864.364 223.537 853.952 240.021 862.629 265.181L997.989 636.505Z'
        className={styles.text}
      />
      <path
        d='M1302.78 444.77C1302.78 570.569 1393.89 676.413 1540.53 676.413C1609.95 676.413 1662.01 650.386 1690.64 632.167C1709.73 620.888 1713.2 603.537 1702.79 583.582L1692.38 566.231C1681.1 546.276 1665.48 543.674 1643.79 554.085C1622.96 567.098 1589.12 582.715 1548.34 582.715C1481.53 582.715 1422.52 541.071 1415.58 461.254H1685.43C1707.13 461.254 1723.61 443.035 1723.61 423.948C1723.61 302.487 1653.33 213.126 1527.51 213.126C1394.76 213.126 1302.78 308.56 1302.78 444.77ZM1418.19 394.45C1428.6 335.455 1468.51 296.414 1524.91 296.414C1575.24 296.414 1612.55 332.852 1614.28 394.45H1418.19Z'
        className={styles.text}
      />
      <path
        d='M1804.86 508.103C1804.86 627.829 1898.57 670.34 1977.53 670.34C2007.9 670.34 2022.65 659.929 2022.65 633.902V607.007C2022.65 582.715 2013.11 574.039 1986.21 571.436C1955.84 567.966 1915.06 554.952 1915.06 489.016V317.236H1980.13C2002.69 317.236 2015.71 305.089 2015.71 280.797V266.048C2015.71 242.624 2002.69 229.61 1979.27 229.61H1915.06V139.382C1915.06 115.957 1902.04 102.943 1878.61 102.943H1843.91C1820.48 102.943 1807.46 115.957 1807.46 139.382V229.61H1782.3C1758 229.61 1745.86 242.624 1745.86 266.048V280.797C1745.86 305.089 1758 317.236 1781.43 317.236H1804.86V508.103Z'
        className={styles.text}
      />
      <path
        d='M2044.38 444.77C2044.38 573.171 2138.09 676.413 2284.73 676.413C2361.09 676.413 2414.88 645.181 2441.78 625.226C2459.14 613.08 2461.74 595.729 2451.33 576.642L2442.65 560.158C2431.37 539.336 2414.88 536.733 2393.19 548.879C2372.37 563.628 2337.66 582.715 2294.27 582.715C2209.24 582.715 2156.31 518.514 2156.31 443.902C2156.31 368.423 2209.24 306.825 2290.8 306.825C2328.98 306.825 2358.48 321.573 2376.71 333.72C2397.53 344.13 2412.28 344.13 2425.3 324.176L2436.58 306.825C2448.72 287.738 2447.86 269.519 2429.63 257.373C2403.6 239.153 2355.88 213.126 2284.73 213.126C2138.09 213.126 2044.38 318.971 2044.38 444.77Z'
        className={styles.text}
      />
      <path
        d='M2479.16 444.77C2479.16 580.98 2587.62 676.413 2722.12 676.413C2856.61 676.413 2965.94 580.98 2965.94 444.77C2965.94 309.427 2856.61 213.126 2722.12 213.126C2587.62 213.126 2479.16 309.427 2479.16 444.77ZM2591.1 444.77C2591.1 363.217 2650.1 306.825 2722.12 306.825C2794.14 306.825 2854.01 363.217 2854.01 444.77C2854.01 527.19 2794.14 582.715 2722.12 582.715C2650.1 582.715 2591.1 527.19 2591.1 444.77Z'
        className={styles.text}
      />
      <path
        d='M3119.37 636.505C3125.45 657.327 3139.33 666.003 3160.16 666.003H3229.57C3250.4 666.003 3263.41 657.327 3270.35 636.505L3403.98 265.181C3412.65 240.021 3401.37 223.537 3374.48 223.537H3333.69C3312.87 223.537 3298.99 233.08 3292.91 253.902L3210.48 497.692C3201.8 523.719 3194.86 564.496 3194.86 564.496H3193.13C3193.13 564.496 3185.32 523.719 3176.64 497.692L3094.21 253.902C3088.14 233.08 3074.25 223.537 3053.43 223.537H3012.65C2985.75 223.537 2975.34 240.021 2984.01 265.181L3119.37 636.505Z'
        className={styles.text}
      />
      <path
        d='M3424.17 444.77C3424.17 570.569 3515.27 676.413 3661.91 676.413C3731.33 676.413 3783.39 650.386 3812.03 632.167C3831.11 620.888 3834.59 603.537 3824.17 583.582L3813.76 566.231C3802.48 546.276 3786.86 543.674 3765.17 554.085C3744.35 567.098 3710.51 582.715 3669.72 582.715C3602.91 582.715 3543.91 541.071 3536.97 461.254H3806.82C3828.51 461.254 3845 443.035 3845 423.948C3845 302.487 3774.71 213.126 3648.9 213.126C3516.14 213.126 3424.17 308.56 3424.17 444.77ZM3539.57 394.45C3549.98 335.455 3589.9 296.414 3646.3 296.414C3696.62 296.414 3733.93 332.852 3735.67 394.45H3539.57Z'
        className={styles.text}
      />
      <path
        d='M443.795 480.035C437.178 457.78 422.284 438.907 402.176 427.298C382.069 415.689 358.278 412.227 335.696 417.624L234.905 441.641C177.928 454.031 167.401 534.521 219.311 561.111C247.094 576.172 277.404 574.988 306.656 592.589C337.593 609.738 353.22 635.22 379.325 653.495C428.249 683.253 490.933 633.855 473.324 579.292L443.795 480.035Z'
        className={styles.paw}
      />
      <path
        d='M608.349 454.218C585.748 434.497 549.89 451.607 530.719 473.101C471.855 539.92 536.801 596.634 596.289 533.201C621.441 505.817 626.738 471.063 608.349 454.218V454.218Z'
        className={styles.paw}
      />
      <path
        d='M476.738 432.683C502.095 447.323 539.357 430.401 559.775 395.035C606.398 308.426 519.518 258.266 467.891 341.985C447.473 377.351 451.449 418.082 476.738 432.683V432.683Z'
        className={styles.paw}
      />
      <path
        d='M298.239 338.879C307.335 311.567 304.157 271.92 275.777 262.208C221.638 246.876 183.522 359.126 229.543 386.052C256.201 400.287 285.808 376.899 298.239 338.879V338.879Z'
        className={styles.paw}
      />
      <path
        d='M360.232 365.418C385.522 380.019 422.784 363.097 443.202 327.731C489.891 241.161 403.012 191.001 351.318 274.682C330.899 310.047 334.875 350.778 360.232 365.418V365.418Z'
        className={styles.paw}
      />
      <path
        d='M451.156 56.1973C420.616 37.8824 382.641 37.1899 351.454 54.3793L96.3339 194.994C64.423 212.582 44.6045 246.135 44.6045 282.572L44.6045 568.37C44.6045 603.232 62.7601 635.577 92.5198 653.735L340.195 804.852C370.846 823.553 409.17 824.387 440.604 807.036L695.128 666.546C727.009 648.949 746.804 615.412 746.804 578.997V290.133C746.804 254.999 728.366 222.443 698.235 204.373L451.156 56.1973ZM664.328 530.798C663.805 566.691 644.083 599.551 612.654 616.895L443.472 710.255C412.49 727.352 374.779 726.802 344.308 708.809L178.098 610.657C147.189 592.405 128.434 558.986 128.957 523.093L131.719 333.483C132.242 297.589 151.964 264.73 183.393 247.386L352.575 154.025C383.558 136.928 421.269 137.478 451.739 155.472L617.95 253.623C648.859 271.876 667.613 305.295 667.09 341.187L664.328 530.798Z'
        className={styles.text}
      />
      <path
        d='M997.989 636.505C1004.06 657.327 1017.95 666.003 1038.77 666.003H1108.19C1129.01 666.003 1142.03 657.327 1148.97 636.505L1282.59 265.181C1291.27 240.021 1279.99 223.537 1253.09 223.537H1212.31C1191.48 223.537 1177.6 233.08 1171.53 253.902L1089.1 497.692C1080.42 523.719 1073.48 564.496 1073.48 564.496H1071.74C1071.74 564.496 1063.93 523.719 1055.26 497.692L972.826 253.902C966.752 233.08 952.869 223.537 932.044 223.537H891.263C864.364 223.537 853.952 240.021 862.629 265.181L997.989 636.505Z'
        className={styles.text}
      />
      <path
        d='M1302.78 444.77C1302.78 570.569 1393.89 676.413 1540.53 676.413C1609.95 676.413 1662.01 650.386 1690.64 632.167C1709.73 620.888 1713.2 603.537 1702.79 583.582L1692.38 566.231C1681.1 546.276 1665.48 543.674 1643.79 554.085C1622.96 567.098 1589.12 582.715 1548.34 582.715C1481.53 582.715 1422.52 541.071 1415.58 461.254H1685.43C1707.13 461.254 1723.61 443.035 1723.61 423.948C1723.61 302.487 1653.33 213.126 1527.51 213.126C1394.76 213.126 1302.78 308.56 1302.78 444.77ZM1418.19 394.45C1428.6 335.455 1468.51 296.414 1524.91 296.414C1575.24 296.414 1612.55 332.852 1614.28 394.45H1418.19Z'
        className={styles.text}
      />
      <path
        d='M1804.86 508.103C1804.86 627.829 1898.57 670.34 1977.53 670.34C2007.9 670.34 2022.65 659.929 2022.65 633.902V607.007C2022.65 582.715 2013.11 574.039 1986.21 571.436C1955.84 567.966 1915.06 554.952 1915.06 489.016V317.236H1980.13C2002.69 317.236 2015.71 305.089 2015.71 280.797V266.048C2015.71 242.624 2002.69 229.61 1979.27 229.61H1915.06V139.382C1915.06 115.957 1902.04 102.943 1878.61 102.943H1843.91C1820.48 102.943 1807.46 115.957 1807.46 139.382V229.61H1782.3C1758 229.61 1745.86 242.624 1745.86 266.048V280.797C1745.86 305.089 1758 317.236 1781.43 317.236H1804.86V508.103Z'
        className={styles.text}
      />
      <path
        d='M2044.38 444.77C2044.38 573.171 2138.09 676.413 2284.73 676.413C2361.09 676.413 2414.88 645.181 2441.78 625.226C2459.14 613.08 2461.74 595.729 2451.33 576.642L2442.65 560.158C2431.37 539.336 2414.88 536.733 2393.19 548.879C2372.37 563.628 2337.66 582.715 2294.27 582.715C2209.24 582.715 2156.31 518.514 2156.31 443.902C2156.31 368.423 2209.24 306.825 2290.8 306.825C2328.98 306.825 2358.48 321.573 2376.71 333.72C2397.53 344.13 2412.28 344.13 2425.3 324.176L2436.58 306.825C2448.72 287.738 2447.86 269.519 2429.63 257.373C2403.6 239.153 2355.88 213.126 2284.73 213.126C2138.09 213.126 2044.38 318.971 2044.38 444.77Z'
        className={styles.text}
      />
      <path
        d='M2479.16 444.77C2479.16 580.98 2587.62 676.413 2722.12 676.413C2856.61 676.413 2965.94 580.98 2965.94 444.77C2965.94 309.427 2856.61 213.126 2722.12 213.126C2587.62 213.126 2479.16 309.427 2479.16 444.77ZM2591.1 444.77C2591.1 363.217 2650.1 306.825 2722.12 306.825C2794.14 306.825 2854.01 363.217 2854.01 444.77C2854.01 527.19 2794.14 582.715 2722.12 582.715C2650.1 582.715 2591.1 527.19 2591.1 444.77Z'
        className={styles.text}
      />
      <path
        d='M3119.37 636.505C3125.45 657.327 3139.33 666.003 3160.16 666.003H3229.57C3250.4 666.003 3263.41 657.327 3270.35 636.505L3403.98 265.181C3412.65 240.021 3401.37 223.537 3374.48 223.537H3333.69C3312.87 223.537 3298.99 233.08 3292.91 253.902L3210.48 497.692C3201.8 523.719 3194.86 564.496 3194.86 564.496H3193.13C3193.13 564.496 3185.32 523.719 3176.64 497.692L3094.21 253.902C3088.14 233.08 3074.25 223.537 3053.43 223.537H3012.65C2985.75 223.537 2975.34 240.021 2984.01 265.181L3119.37 636.505Z'
        className={styles.text}
      />
      <path
        d='M3424.17 444.77C3424.17 570.569 3515.27 676.413 3661.91 676.413C3731.33 676.413 3783.39 650.386 3812.03 632.167C3831.11 620.888 3834.59 603.537 3824.17 583.582L3813.76 566.231C3802.48 546.276 3786.86 543.674 3765.17 554.085C3744.35 567.098 3710.51 582.715 3669.72 582.715C3602.91 582.715 3543.91 541.071 3536.97 461.254H3806.82C3828.51 461.254 3845 443.035 3845 423.948C3845 302.487 3774.71 213.126 3648.9 213.126C3516.14 213.126 3424.17 308.56 3424.17 444.77ZM3539.57 394.45C3549.98 335.455 3589.9 296.414 3646.3 296.414C3696.62 296.414 3733.93 332.852 3735.67 394.45H3539.57Z'
        className={styles.text}
      />
      <path
        d='M443.795 480.035C437.178 457.78 422.284 438.907 402.176 427.298C382.069 415.689 358.278 412.227 335.696 417.624L234.905 441.641C177.928 454.031 167.401 534.521 219.311 561.111C247.094 576.172 277.404 574.988 306.656 592.589C337.593 609.738 353.22 635.22 379.325 653.495C428.249 683.253 490.933 633.855 473.324 579.292L443.795 480.035Z'
        className={styles.paw}
      />
      <path
        d='M608.349 454.218C585.748 434.497 549.89 451.607 530.719 473.101C471.855 539.92 536.801 596.634 596.289 533.201C621.441 505.817 626.738 471.063 608.349 454.218V454.218Z'
        className={styles.paw}
      />
      <path
        d='M476.738 432.683C502.095 447.323 539.357 430.401 559.775 395.035C606.398 308.426 519.518 258.266 467.891 341.985C447.473 377.351 451.449 418.082 476.738 432.683V432.683Z'
        className={styles.paw}
      />
      <path
        d='M298.239 338.879C307.335 311.567 304.157 271.92 275.777 262.208C221.638 246.876 183.522 359.126 229.543 386.052C256.201 400.287 285.808 376.899 298.239 338.879V338.879Z'
        className={styles.paw}
      />
      <path
        d='M360.232 365.418C385.522 380.019 422.784 363.097 443.202 327.731C489.891 241.161 403.012 191.001 351.318 274.682C330.899 310.047 334.875 350.778 360.232 365.418V365.418Z'
        className={styles.paw}
      />
    </svg>
  );
};

export default VetcoveLogo;
