import type { RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const bodyScroll = {
  prevent: () => {
    const scrolBarWidth = window.innerWidth - document.documentElement.clientWidth;
    // Add padding to make up for removing the scroll bar
    document.body.style.padding = `0 ${scrolBarWidth}px 0 0`;
    document.body.style.overflow = 'hidden';
  },
  allow: () => {
    // Put it all back when we unmount
    document.body.style.removeProperty('padding');
    document.body.style.removeProperty('overflow');
  },
  isAlreadyPrevented: () => {
    return document.body.style.overflow === 'hidden';
  },
};

/**
 * A hook that when mounted prevents scrolling on the body
 */
export const useNoBodyScroll = (isActive = true) => {
  useEffect(() => {
    if (!isActive) {
      bodyScroll.allow();
      return () => {};
    }

    // Don't do anything if we are already preventing the scrolling, the first one to prevent will allow again via the
    // unmount function that runs at the end
    if (bodyScroll.isAlreadyPrevented()) {
      return () => {};
    }

    bodyScroll.prevent();

    return () => bodyScroll.allow();
  }, [isActive]);
};

enum FocusTrapState {
  Starting = 'start',
  On = 'on',
  Paused = 'pause',
  Off = 'off',
}

/**
 * Hook to trap and return focus.
 *
 * Can be activated by calling `trap`, suspended by calling `pause`, or stopped
 * (and focus returned) by calling `drop` All functions can be called repeatably
 * without any adverse effects.
 *
 * Calling `pause` or `drop` on a stopped/paused trap, or calling `trap` on an activated
 * trap will do nothing.
 * Calling `trap` on a paused trap will resume focus tracking.
 */
export const useFocusTrap = (containerRef: RefObject<HTMLElement>) => {
  const fromElementRef = useRef<Element | null>(null);
  const [state, setState] = useState(FocusTrapState.Off);

  const initializeFocus = useCallback(() => {
    fromElementRef.current = document.activeElement;
    if (containerRef.current) {
      containerRef.current.focus({ preventScroll: true });
    }
    setState(FocusTrapState.On);
  }, [containerRef]);

  const trapFocus = useCallback(() => {
    const container = containerRef.current;
    if (!container) {
      return undefined;
    }
    const handleGlobalFocus = (e: FocusEvent) => {
      if (!container) {
        return;
      }

      if (e.target instanceof Node && !container.contains(e.target)) {
        e.preventDefault();
        container.focus({ preventScroll: true });
      }
    };

    // uses `focusin` because `focus` does not bubble
    document.addEventListener('focusin', handleGlobalFocus, true);

    return () => document.removeEventListener('focusin', handleGlobalFocus, true);
  }, [containerRef]);

  const dropFocus = useCallback(() => {
    const element = fromElementRef.current;
    if (element && element instanceof HTMLElement) {
      element.focus({ preventScroll: true });
    }
    fromElementRef.current = null;
  }, []);

  useEffect(() => {
    switch (state) {
      case FocusTrapState.Starting:
        // capture fromElement and focus on container if possible
        return initializeFocus();
      case FocusTrapState.Off:
        // stop listening for external focuses, and return focus to fromElement if it exists
        return dropFocus();
      case FocusTrapState.Paused:
        // don't listen for external focuses, but retain fromElement if it exists
        return undefined;
      default:
        // listen to focus events, and redirect external focuses to container
        return trapFocus();
    }
  }, [state, initializeFocus, dropFocus, trapFocus]);

  return useMemo(() => {
    return {
      trap: () =>
        setState((s) => (s === FocusTrapState.Off ? FocusTrapState.Starting : FocusTrapState.On)),
      pause: () => setState((s) => (s === FocusTrapState.On ? FocusTrapState.Paused : s)),
      drop: () => setState(FocusTrapState.Off),
    };
  }, []);
};

/**
 * Returns a boolean if the component is mounted
 *
 * Used for places where the DOM is required since NextJS generates code server side
 */
export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};
