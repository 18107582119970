import NextImage, { ImageProps as NextImageProps } from 'next/image';

export type ImageProps = NextImageProps;

/**
 * Wrapper component for Next's Image component
 *
 * Used to update their defaul values to default values we'd like to support instead
 */
const Image = (props: ImageProps) => <NextImage priority={true} quality={100} {...props} />;

export default Image;
