import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';

import LinkButton, { LinkButtonProps } from './LinkButton';

import styles from './Button.module.scss';

export type ButtonProps = LinkButtonProps & {
  variant?: 'primary' | 'secondary';
};

const Button = (props: ButtonProps) => {
  const { variant = 'primary', className = '', children, ...other } = props;

  return (
    <LinkButton
      className={`${styles.button} ${styles[variant]} ${className}`}
      arrowIcon={faArrowCircleRight}
      {...other}
    >
      {children}
    </LinkButton>
  );
};

export default Button;
